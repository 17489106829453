

import React, { useState } from 'react';
function Feed() {
    return (

        <div> 
            Feed Page
        </div>
    );
}

export default Feed;
