

import React, { useState } from 'react';
function Sync() {
    return (

        <div> 
        Sync
        </div>
    );
}

export default Sync;
