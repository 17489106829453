import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAdpMFKWIDsqT6uQzWzN8ZEDvtw4c9R8Ig",
  authDomain: "got-your-back-34b51.firebaseapp.com",
  projectId: "got-your-back-34b51",
  storageBucket: "got-your-back-34b51.appspot.com",
  messagingSenderId: "86192186217",
  appId: "1:86192186217:web:19aae35bd888606e7f53f8",
  measurementId: "G-MQPNGSGPEV",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };