

import React, { useState } from 'react';
function Collaborators() {
    return (

        <div> 
           Collaborators
        </div>
    );
}

export default Collaborators;
