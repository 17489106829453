

import React, { useState } from 'react';
function PaymenSchedules() {
    return (

        <div> 
            Payment Schedules
        </div>
    );
}

export default PaymenSchedules;
