import React, { useCallback, useEffect, useRef, useState } from "react";
import { useUpdateEffect } from "../hooks/useUpdateEffect";

const Otp = ({ elCount, loading, error = null, onFinish }) => {
  const inputRefs = useRef([]);
  const [keyPress, setKeyPress] = useState(null);
  const [otp, setOtp] = useState({});

  const onOtpChange = useCallback(
    (keyIndex) => (e) => {
      setOtp((prev) => ({ ...prev, [keyIndex]: e.target.value }));
      if (keyPress === "Backspace") {
        if (keyIndex - 1 >= 0) {
          inputRefs.current[keyIndex - 1].focus();
          inputRefs.current[keyIndex - 1] = "";
        }
      } else {
        if (keyIndex + 1 < elCount) {
          inputRefs.current[keyIndex + 1].focus();
        } else {
          inputRefs.current[keyIndex].blur();
        }
      }
    },
    [elCount, keyPress]
  );

  useEffect(() => {
    if (!!error) {
      setOtp((prev) => {
        const defaultPrev = Object.entries(prev).reduce((acc, c) => {
          acc = { ...acc, [c[0]]: "" };
          return acc;
        }, {});
        return (prev = { ...defaultPrev });
      });
      inputRefs.current.map((d) => (d.value = ""));
    }
  }, [error]);

  useUpdateEffect(() => {
    const isOtpEnd =
      Object.values(otp).filter((val) => !!val).length === elCount;
    if (isOtpEnd) {
      onFinish?.(Object.values(otp).join(""));
    }
  }, [otp]);

  return (
    <div className="flex flex-col items-center">
      <div className="flex space-x-2">
        {elCount &&
          !loading &&
          Array.from({ length: elCount }).map((_, i) => {
            return (
              <input
                ref={(e) => inputRefs.current && (inputRefs.current[i] = e)}
                key={i}
                className="border-1 border-gray-400 rounded-md h-[60px] w-[45px] bg-white focus:outline-none text-lg text-center"
                onChange={onOtpChange(i)}
                onKeyDown={(e) => setKeyPress(() => e.key)}
                type="password"
                maxLength={1}
              />
            );
          })}
        {loading && <p className="font-bold text-[30px]">Verfying Code...</p>}
      </div>
      {!loading && !!error && (
        <p className="w-[80%] lg:w-full text-center font-bold text-[19px] text-red-500 mt-1">
          {error}
        </p>
      )}
    </div>
  );
};

export default Otp;
