

import React, { useState } from 'react';
function UploadAlbum() {
    return (

        <div> 
            Upload Album Page
        </div>
    );
}

export default UploadAlbum;
