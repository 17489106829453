

import React, { useState } from 'react';
function MapATour() {
    return (

        <div> 
            MapATour
        </div>
    );
}

export default MapATour;
