

import React, { useState } from 'react';
function Agreements() {
    return (

        <div> 
           Agreements
        </div>
    );
}

export default Agreements;
