

import React, { useState } from 'react';
function SalesPurchase() {
    return (

        <div> 
            Sales Purchase
        </div>
    );
}

export default SalesPurchase;
