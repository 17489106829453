

import React, { useState } from 'react';
function Deposits() {
    return (

        <div> 
            Deposits
        </div>
    );
}

export default Deposits;
