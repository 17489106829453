

import React, { useState } from 'react';
import Hendrix from '../../assets/profilepics/hendrix.jpg';
function Chatbox() {

    const profiles = [
        { firstname: 'Allen', lastname: 'Young',img: '../../assets/profilepics/abs.png' },
        { firstname: 'Piolo Pascual', lastname: 'Young', img: '../../assets/profilepics/abs.png' },
    ];



    return (
        <div>
            <div className="font-semibold">
                Artist Active
            </div>
            <div className="mt-3">
                {profiles.map((profile, index) => (
                        <div className="flex mt-3">
                            <div className="mr-2">
                                <img className="rounded-full h-8 w-8 object-cover" src={Hendrix} alt={`${profile.firstname} ${profile.lastname}`} />
                            </div>

                            <p className="mt-1 text-sm">{profile.firstname} {profile.lastname}</p>
                     

                            <div className="ml-auto mt-1">    
                                <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="4.30078" cy="3.62305" r="3.5" fill="#5EC46F"/>
                                </svg>
                            </div>

                         
                        </div>    
                        
                ))}
            </div>
        </div>
    );
}

export default Chatbox;
