

import React, { useState } from 'react';
function SalesLicense() {
    return (

        <div> 
            Sales License
        </div>
    );
}

export default SalesLicense;
