

import React, { useState } from 'react';
function Documents() {
    return (

        <div> 
           Documents
        </div>
    );
}

export default Documents;
