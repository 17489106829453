import React from "react";
import { faqList } from "../../../constants/data/faqlist";
import gybLogo from "../../../assets/image/pre-signup/gyb-white-logo.png";
import { useDimensions } from "../../../hooks/useWindowDimension";

const FAQ = () => {
  const { phone } = useDimensions();
  return (
    <div className="min-h-full flex flex-col items-center py-4 overflow-y-auto space-y-5 bg-[#FCC703]">
      {!phone && (
        <div className="fixed w-full h-full top-0 z-0 flex items-end justify-center">
          <div className="h-[87%] w-[90%]">
            <p className="text-[750px] text-white font-extrabold opacity-[0.5]">
              FAQ'S
            </p>
          </div>
        </div>
      )}
      <div className="w-[90%] flex flex-col items-center lg:flex-row lg:space-x-3 z-10">
        <div className={phone ? "order-2" : ""}>
          <p className="text-[29.5px] lg:text-[50px] font-extrabold text-white">
            FREQUENTLY ASKED QUESTIONS
          </p>
        </div>
        <div className={`h-[100px] w-[100px] ${phone ? "mb-3" : ""}`}>
          <img src={gybLogo} alt="" className="h-full w-full object-contain" />
        </div>
      </div>
      <div className="w-[90%] grid grid-cols-1 lg:grid-cols-4 gap-5 z-0">
        {(!phone ? faqList : faqList.sort((a, b) => a.key - b.key)).map(
          ({ key, title, descriptions, bulletedList, elDesc }) => {
            return (
              <div className="flex flex-col space-y-2" key={key}>
                <p className="text-[#444444] text-[28px] font-bold">
                  {key}. {title}
                </p>
                {!!descriptions &&
                  descriptions.map((desc, i) => {
                    return elDesc ? (
                      <div key={i}>{desc}</div>
                    ) : (
                      <div key={i} className="text-[#444444] text-[20px]">
                        {desc}
                      </div>
                    );
                  })}
                {!!bulletedList &&
                  bulletedList.map((bl, i) => {
                    return (
                      <div key={i} className="flex space-x-2">
                        <div className="text-[20px]">-</div>
                        <div className="text-[20px] text-[#444444]">{bl}</div>
                      </div>
                    );
                  })}
              </div>
            );
          }
        )}
        <div className="px-10">
          <div>
            <p className="text-white font-extrabold text-[30px] text-center">
              If you have any more specific questions, email us!
            </p>
            <p className="text-[#444444] text-[30px] font-extrabold text-center mt-3">
              support@gotyouback.io
            </p>
            <p className="text-center text-[#444444] text-[20px] mt-5">
              GYB Got Your Back 2024 ©
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
