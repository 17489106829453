

import React, { useState } from 'react';
function Bookings() {
    return (

        <div> 
            Bookings
        </div>
    );
}

export default Bookings;
