

import React, { useState } from 'react';
function PromoteMusic() {
    return (

        <div> 
            Promo Music
        </div>
    );
}

export default PromoteMusic;
