

import React, { useState } from 'react';
function SavedDrafts() {
    return (

        <div> 
            Save Drafts
        </div>
    );
}

export default SavedDrafts;
