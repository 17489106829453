

import React, { useState } from 'react';
function Analytics() {
    return (

        <div> 
           Analytics
        </div>
    );
}

export default Analytics;
