

import React, { useState } from 'react';
function Royalties() {
    return (

        <div> 
            Royalties
        </div>
    );
}

export default Royalties;
