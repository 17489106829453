

import React, { useState } from 'react';
function PayReceive() {
    return (

        <div> 
            PayReceive
        </div>
    );
}

export default PayReceive;
