import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from "./component/landingpage";
import Registration from "./component/pages/home/registration";
import Feed from "./component/pages/home/feed";
import Customize from "./component/pages/home/customize";

import Demo from "./component/pages/mymusic/demos";
import GybLive from "./component/pages/mymusic/gybLive";
import MyLibrary from "./component/pages/mymusic/myLibrary";
import Sync from "./component/pages/mymusic/sync";
import UploadAlbum from "./component/pages/mymusic/uploadAlbum";
import UploadSong from "./component/pages/mymusic/uploadSong";

import Collaborators from "./component/pages/community/collaborators";
import Discord from "./component/pages/community/discord";
import Friends from "./component/pages/community/friends";

import Agreements from "./component/pages/assets/agreements";
import Documents from "./component/pages/assets/documents";
import Lyrics from "./component/pages/assets/lyrics";

import Bookings from "./component/pages/promote/bookings";
import MapAtour from "./component/pages/promote/mapAtour";
import PromoAssets from "./component/pages/promote/promoAssets";
import PromoteMusic from "./component/pages/promote/promoteMusic";
import PromoteShows from "./component/pages/promote/promoteShows";
import SavedDrafts from "./component/pages/promote/savedDrafts";

import Analytics from "./component/pages/analytics/analytics";
import Metadata from "./component/pages/analytics/metadata";

import Royalties from "./component/pages/royalties/royalties";
import Deposits from "./component/pages/royalties/deposits";
import PayReceive from "./component/pages/royalties/payreceive";
import SalesPurchase from "./component/pages/royalties/salespurchase";
import SalesLicense from "./component/pages/royalties/saleslicense";
import PaymentSchedules from "./component/pages/royalties/paymentschedules";

import AccessCode from "./component/pages/earlyregistratioin";
import PreSignUp from "./component/pages/pre-signup/presignup";
import PreSignupSuccess from "./component/pages/pre-signup/presignupsuccess";
import Faq from "./component/pages/faq/faq";

function App() {
  return (
    <div className="h-full">
      <Router>
        <Routes>
          <Route path="/" element={<AccessCode />} />
          <Route path="/pre-signup" element={<PreSignUp />} />
          <Route path="/pre-signup/success" element={<PreSignupSuccess />} />

          <Route path="/home" element={<LandingPage />} />
          <Route path="/faq" element={<Faq />} />

          <Route path="/registration" element={<Registration />} />
          <Route path="/feed" element={<Feed />} />
          <Route path="/customize" element={<Customize />} />

          <Route path="/mymusic/demo" element={<Demo />} />
          <Route path="/mymusic/gyblive" element={<GybLive />} />
          <Route path="/mymusic/mylibrary" element={<MyLibrary />} />
          <Route path="/mymusic/sync" element={<Sync />} />
          <Route path="/mymusic/uploadalbum" element={<UploadAlbum />} />
          <Route path="/mymusic/uploadsong" element={<UploadSong />} />

          <Route path="/community/Collaborators" element={<Collaborators />} />
          <Route path="/community/Discord" element={<Discord />} />
          <Route path="/community/Friends" element={<Friends />} />

          <Route path="/assets/agreements" element={<Agreements />} />
          <Route path="/assets/documents" element={<Documents />} />
          <Route path="/assets/lyrics" element={<Lyrics />} />

          <Route path="/promote/bookings" element={<Bookings />} />
          <Route path="/promote/maptour" element={<MapAtour />} />
          <Route path="/promote/promoassets" element={<PromoAssets />} />
          <Route path="/promote/promotemusic" element={<PromoteMusic />} />
          <Route path="/promote/promoteshows" element={<PromoteShows />} />
          <Route path="/promote/saveddrafts" element={<SavedDrafts />} />

          <Route path="/analytics/analytics" element={<Analytics />} />
          <Route path="/analytics/metadata" element={<Metadata />} />

          <Route path="/royalties/royalties" element={<Royalties />} />
          <Route path="/royalties/deposits" element={<Deposits />} />
          <Route path="/royalties/payreceive" element={<PayReceive />} />
          <Route path="/royalties/salespurchases" element={<SalesPurchase />} />
          <Route path="/royalties/saleslicense" element={<SalesLicense />} />
          <Route
            path="/royalties/paymentschedules"
            element={<PaymentSchedules />}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
