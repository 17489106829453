

import React, { useState } from 'react';
function GybLive() {
    return (

        <div> 
           GybLive
        </div>
    );
}

export default GybLive;
