

import React, { useState } from 'react';
function Footer() {
    return (

        <div>
            <div className="flex space-x-5 justify-center py-3 font-semibold text-sm">
                <div>
                    About 
                </div>
                <div>
                    Help 
                </div>
                <div>
                    Careers 
                </div>
                <div>
                    Privacy Policy 
                </div>
                <div>
                    Contact Us 
                </div>
                <div>
                    Terms of Use 
                </div>
                <div>
                    Follow Us 
                </div>
                <div>
                    &copy; 2024 GYB 
                </div>
            </div> 
        </div>
    );
}

export default Footer;
