

import React, { useState } from 'react';
function PromoAssets() {
    return (

        <div> 
            Promo Assets
        </div>
    );
}

export default PromoAssets;
