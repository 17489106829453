

import React, { useState } from 'react';
function Discord() {
    return (

        <div> 
           Discord
        </div>
    );
}

export default Discord;
