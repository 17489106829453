

import React, { useState } from 'react';
function Friends() {
    return (

        <div> 
           Friends
        </div>
    );
}

export default Friends;
